import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../layouts/SimplePage';
import FirstScreenSection from '../../components/FirstScreenSection';
/**
 * @todo simplify import statements
 * @example
 * // Bad
 * import A from 'A';
 * import AB from 'A/AB';
 * import AC from 'A/AC';
 *
 * // Good
 * import A, { AB, AC } from 'A';
 */
import BenefitsSection from '../../components/BenefitsSection';
import BenefitsHeading from '../../components/BenefitsSection/BenefitsHeading';
import BenefitsItem from '../../components/BenefitsSection/BenefitsItem';
import BenefitsItemHeading from '../../components/BenefitsSection/BenefitsItemHeading';
import Calculator from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import Samples from '../../components/Samples';
import SamplesContent from '../../components/Samples/SamplesContent';
import SamplesHeading from '../../components/Samples/SamplesHeading';
import SamplesSlider from '../../components/Samples/SamplesSlider';
import SamplesItem from '../../components/Samples/SamplesItem';
import LastCompletedProjects from '../../components/LastCompletedProjects';
import StepsSection from '../../components/StepsSection';
import StepsContent from '../../components/StepsSection/StepsContent';
import StepsHeading from '../../components/StepsSection/StepsHeading';
import StepsItem from '../../components/StepsSection/StepsItem';
import VerticalTextBlock from '../../components/VerticalTextBlock';
import { SeoTextSection, SeoTextHeading, SeoTextContent } from '../../components/SeoText';
import CTA from '../../components/CTA';

import './index.scss';


const PrivateWritingServicePage = () => {
  const resoursesUrl = '/img/icons/landing--private-writing-service/';

  return (
    <Layout
      pageWrapperClassName="private-writing-service-page"
      headerClassName="header_transparent-bg"
    >

      <Helmet
        title="Private Essay Writer - Anonymous Essay Writing Service"
        meta={[
          {
            name: 'description',
            content: 'Anonymous Essay Writing Service TakeAwayEssay.com 📝 is a place to go for professional Private Essay Writer assistance. We provide quality papers, 24/7 customer service, reliable guarantees, and excellent reviews from our satisfied customers.',
          },

          {
            property: 'og:title',
            content: 'Private Essay Writing Service to Protect Your Privacy and Meet Your Goals | TakeAwayEssay.com',
          },
          {
            property: 'og:description',
            content: 'Reading this page, you will learn about the major advantages and guarantees offered by our private essay writing service. If you place an order on our platform, we promise to write high-quality assignments quickly and will surely protect your personal information.',
          },
          { property: 'og:site_name', content: 'Takeawayessay.com' },
          { property: 'og:type', content: 'website' },
          { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
        ]}
        link={[
          { rel: 'canonical', href: 'https://takeawayessay.com/private-writing-service.html' },
        ]}
      />

      <FirstScreenSection
        btnClassName="awesome-btn_white"
      >
        <h2
          className="first-screen-section__title first-screen-section__title_single-line-h"
        >
          We take care of your private
          <br className="first-screen-section__title__br" />
          {' '}
          information like it’s our own
        </h2>
        <p className="first-screen-section__heading">
          Our writing service ensures the full anonymity
          <br className="first-screen-section__heading__br" />
          {' '}
          of your personal details
        </p>
      </FirstScreenSection>

      <BenefitsSection>
        <BenefitsHeading>
          Your confidentiality
          <br className="benefits-section__heading__br" />
          {' '}
          is essential to us
        </BenefitsHeading>
        <BenefitsItem>
          <BenefitsItemHeading>
            <img
              data-observed-image
              data-src={`${resoursesUrl}encrypted-connection.svg`}
              width={35}
              height={50}
              alt="encrypted connection"
            />
            <h3>
              We use an encrypted connection
            </h3>
          </BenefitsItemHeading>
          <p>
            If we translate this into common language, we will have a secure data exchange between
            our server and your device—just like a
            sealed email, with no one being able to see what’s inside except for the addressee.
          </p>
        </BenefitsItem>
        <BenefitsItem>
          <BenefitsItemHeading>
            <img
              data-observed-image
              data-src={`${resoursesUrl}pass-data.svg`}
              width={46}
              height={45}
              alt="pass data"
            />
            <h3>
              We never pass your data along to anyone
            </h3>
          </BenefitsItemHeading>
          <p>
            There is no way any third party will ever get the information you’ve shared with us, be
            it your teacher, mates, or anyone
            else. We always treat your personal details with maximum care and do not disclose them
            to anyone.
          </p>
        </BenefitsItem>
        <BenefitsItem>
          <BenefitsItemHeading>
            <img
              data-observed-image
              data-src={`${resoursesUrl}data-security.svg`}
              width={46}
              height={43}
              alt="data security"
            />
            <h3>
              We maintain data security checks
            </h3>
          </BenefitsItemHeading>
          <p>
            In order to keep your private information safe, we take a number of crucial steps,
            including recurrent audit trails. It
            allows us to prevent the possibility of a data breach before it happens.
          </p>
        </BenefitsItem>
      </BenefitsSection>

      <Calculator />
      <Testimonials nameOfPageArray="private_writing_service" />

      <Samples>
        <VerticalTextBlock
          sectionClassName="samples"
          dotsStyle="style-4"
          text="Examples"
        />

        <SamplesContent>
          <SamplesHeading>
            <h2>
              Examples of
              <br />
              {' '}
              our essays
            </h2>
            <p>
              To make sure our writers are experienced enough, you can check examples of their work.
              These samples are specially
              designed to represent our writers’ skills and we do not store any similar papers for
              further use. So, there’s no chance you
              could get a pre-written essay instead of a custom one.
            </p>
          </SamplesHeading>
          <SamplesSlider>
            <SamplesItem
              typeOfPaper="Argumentative essay"
              topicTitle="Felony Disenfranchisement in the UK"
              academicLevel="Undergraduate"
              discipline="Law"
              paperFormat="Chicago"
              sources="2"
              totalPages="2"
              pdfFileName="argumentative_essay_law"
            />
            <SamplesItem
              typeOfPaper="Research paper"
              topicTitle="Robotics and the Future of Jobs"
              academicLevel="Master's"
              discipline="Technology"
              paperFormat="Chicago"
              sources="6"
              totalPages="4"
              pdfFileName="research_paper_technology"
            />
          </SamplesSlider>
        </SamplesContent>
      </Samples>

      <LastCompletedProjects />

      <StepsSection>
        <VerticalTextBlock
          sectionClassName="steps"
          dotsStyle="style-4"
          text="Guarantee"
        />

        <StepsContent>
          <StepsHeading>
            <h2>
              How do we protect your anonymity and privacy?
            </h2>
            <p>
              Security is something we are dead serious about. When it comes to keeping one’s
              personal information strictly confidential, any possible means used to do so are good.
              We completely agree with this idea and that&#39;s why we undertake various actions, as
              well as use proven tools and techniques, to guarantee that your private data is safe.
            </p>
            <p>
              Instead of listing all of them, let’s focus only on the most crucial principles we
              follow, which help our private essay
              writing service maintain the highest security standard.
            </p>
          </StepsHeading>
          <StepsItem>
            <p>
              We use HTTPS, which ensures secure data transfers
            </p>
          </StepsItem>
          <StepsItem>
            <p>
              We provide restricted access to identifiable information
            </p>
          </StepsItem>
          <StepsItem>
            <p>
              We constantly check our systems for possible vulnerabilities
            </p>
          </StepsItem>
          <StepsItem>
            <p>
              We delete your data once we don’t need it to provide you with our services
            </p>
          </StepsItem>
        </StepsContent>
      </StepsSection>

      <SeoTextSection>
        <SeoTextHeading>
          Anonymous essay writing service: private essay writer
        </SeoTextHeading>

        <SeoTextContent>
          <div className="seo-text-section__text__css-half-column">
            <p>
              Many students seek legit academic papers on sale via the Internet. They know that
              certain writing services are able to solve merely any complication. They do it fast
              and according to the rules. Nevertheless, some students are still reluctant because
              they are afraid that somebody may use their private data for illegal purposes. They
              ask “can some writing service write for me my assignments and protect my privacy?” The
              answer is positive if you select our private essay writing service.
            </p>
            <p>
              We are called Takeawayessay.Com and we guarantee full confidentiality of your personal
              information.{' '}
              <strong>
                Our confidentiality essay writing service implements the most efficacious measures
                to prevent any leak of information. Simultaneously, we write all types of academic
                papers that match the highest learning standards.
              </strong>{' '}
              There are many other conveniences and guarantees we propose. Read on to determine what
              we’re capable of.
            </p>
            <h2 className="h3">
              Confidentiality essay writing service to keep your private data safe
            </h2>
            <p>
              One of the most important guarantees our customers seek is their privacy. They’re
              aware of multiple situations when hackers stole private data or some malicious
              programs corrupted the entire website. They commonly ask “will your service write for
              me my essays and keep my anonymity?” The answer is 100% positive. Our confidentiality
              essay writing service uses the most efficient protective and preventive measures to
              secure your confidential status.
            </p>
            <p>
              Our databases are guarded 24/7 by advanced software of a new generation of anti-virus
              programs. It’s highly efficient and is regularly updated. Accordingly, it successfully
              resists even the newest viruses and malicious programs. No hackers will be able to
              penetrate through our firewall.
            </p>
            <p>
              Of course, our private essay writing service never shares personal data about its
              customers if it isn’t required by the law. We never gather more data than is permitted
              by the law. We’ll need:
            </p>
            <ul className="dot-list">
              <li>User’s name. Thus, we identify you in our system.</li>
              <li>
                Email address. It’s required to send you notifications about the progress of your
                order and its final version.
              </li>
              <li>
                Your gadget. When we recognize the gadget you use to access our website, we can
                improve the quickness and smoothness of connection.
              </li>
            </ul>
            <p>
              You may fully trust our company and freely acquire any assignment type on the sale you
              require.
            </p>
            <h2 className="h3">Professional writers at an anonymous essay writing service</h2>
            <p>
              Another important guarantee given by Takeawayessay.Com is the quality of your
              assignment. Our college custom essay writing company is rated among the top platforms
              thanks to the professionalism of its staff. It consists of the officially certified
              specialists who easily match the top writing standards to provide you with assignments
              of the best quality.
            </p>
            <p>
              Our staff includes over 400 specialists. Every writer specializes in his or her majors
              and provides different academic functions. When you purchase any paper on our
              platform, you’re welcome to request different kinds of academic assistance. Your
              orders may be written, edited, quoted, proofread, outlined, or rewritten.
            </p>
            <p>It’s possible to request the following pieces of writing:</p>
            <ul className="dot-list">
              <li>Dissertation</li>
              <li>Coursework</li>
              <li>Case study</li>
              <li>Powerpoint presentation</li>
              <li>Term and/or research paper</li>
              <li>Literature review, etc.</li>
            </ul>
            <p>
              Mind that our specialists are approved experts and so, specialize in different
              academic disciplines. When you hire a professional helper on our anonymous essay
              writing service, your assignments can be written in sociology, management, accounting,
              philosophy, medicine, business, science, mathematics, and other disciplines. We’re
              open for anything and you’re free to request any assignment, topic, and discipline.
            </p>
            <h2 className="h3">Buy private essays and enjoy great benefits</h2>
            <p>
              If you select our private essay writing service you surely enjoy the best protection
              of your personal data and the assistance of professional writers. In the meanwhile,
              you require other conditions and guarantees. We understand your demands and happily
              ensure them. Here are other important benefits we offer:
            </p>
            <ul className="dot-list">
              <li>
                <p>Reasonable pricing</p>
                <p>
                  We set relatively cheap prices to make our help affordable. You pay as much as you
                  can afford. Every order is fully customizable. Complete the order form and mention
                  the main instructions like time limit, type of an assignment, its size, quality,
                  etc. Using an online calculator, define how much money should be paid and regulate
                  your demands until the price is acceptable.
                </p>
              </li>
              <li>
                <p>Unique orders</p>
                <p>
                  Our academic writing company guarantees the highest originality of your
                  assignments. We use a reliable plagiarism checker. All the non-unique content will
                  be detected and instantly eliminated from your texts. We likewise cite according
                  to APA, Chicago, MLA, Turabian, etc.
                </p>
              </li>
              <li>
                <p>Progressive delivery</p>
                <p>
                  If you buy private essays on our website, you’ll receive them without delays. We
                  aren’t afraid to accept even the most urgent orders because our writers are
                  extremely fast. They choose the most appropriate writing strategy to meet your
                  deadlines.
                </p>
              </li>
            </ul>

            <p>
              Takeawayessay.Com will surely meet all your preferences to compose papers of the
              highest quality. We guarantee your safety and academic success. Therefore, don’t
              linger and place an order now.
            </p>
          </div>
        </SeoTextContent>
      </SeoTextSection>

      <CTA>
        Need a better grade?
        <br />
        {' '}
        We&#39;ve got you covered.
      </CTA>

    </Layout>
  );
};

export default PrivateWritingServicePage;
